import React, { useEffect, useState } from "react";

const viewsExcelStatus = process.env.REACT_APP_FLAG_VIEWS_EXCEL_EXPORT;
const viewsPPTStatus = process.env.REACT_APP_FLAG_VIEWS_PPT_EXPORT;
const reportsExcelStatus = process.env.REACT_APP_FLAG_REPORTS_EXCEL_EXPORT;
const reportsPPTStatus = process.env.REACT_APP_FLAG_REPORTS_PPT_EXPORT;

function useViewsReportsExportsStatus() {
  const [state, setState] = useState({
    isViewsExcelExportAvailable: false,
    isViewsPPTExportAvailable: false,
    isReportsExcelExportAvailable: false,
    isReportsPPTExportAvailable: false,
    isViewsExportAvailable: false,
    isReportsExportAvailable: false,
    isReportAndViewsExportAvailable: false,
  });

  useEffect(() => {
    setState({
      isViewsExcelExportAvailable: viewsExcelStatus === "true",
      isViewsPPTExportAvailable: viewsPPTStatus === "true",
      isReportsExcelExportAvailable: reportsExcelStatus === "true",
      isReportsPPTExportAvailable: reportsPPTStatus === "true",
      isViewsExportAvailable:
        viewsExcelStatus === "true" || viewsPPTStatus === "true",
      isReportsExportAvailable:
        reportsExcelStatus === "true" || reportsPPTStatus === "true",
      isReportAndViewsExportAvailable:
        viewsExcelStatus === "true" ||
        viewsPPTStatus === "true" ||
        reportsExcelStatus === "true" ||
        reportsPPTStatus === "true",
    });
  }, []);

  return state;
}

export default useViewsReportsExportsStatus;
