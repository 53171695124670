import {
  ADD_MESSAGE,
  USER_TYPING,
  ASSISTANT_RESPONDING,
  ADD_BULK_MESSAGE
} from "./actiontypes/actiontypes";

const initialState = {
  messages: [],
  isUserTyping: false,
  isAssistantResponding: false,
};

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_MESSAGE":
      const updatedMessages = [...state.messages, action.payload];
      // Save messages to localStorage
      localStorage.setItem("messages", JSON.stringify(updatedMessages));
      return { ...state, messages: updatedMessages };
    case USER_TYPING:
      return {
        ...state,
        isUserTyping: action.payload,
      };
    case ASSISTANT_RESPONDING:
      return {
        ...state,
        isAssistantResponding: action.payload,
      };
    case ADD_BULK_MESSAGE:
      console.log("this is the action payload");
      console.log(action.payload);
      return {
        ...state,
        messages: action.payload,
      };
    default:
      return state;
  }
};

export default chatReducer;
