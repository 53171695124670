import combineEvents from "../../utils/combineEvents";
import {
  DISABLE_Z_AXIS,
  ENABLE_Z_AXIS,
  SET_SEGMENTS,
  SET_CUSTOM_SEGMENTS,
  DISABLE_SEGMENT,
  DISABLE_SEGMENTS,
  DISABLE_CUSTOM_SEGMENT,
  DISABLE_CUSTOM_SEGMENTS,
  ENABLE_SEGMENT,
  ENABLE_CUSTOM_SEGMENT,
  SET_BRANDS,
  DISABLE_BRAND,
  ENABLE_BRAND,
  SET_CHART_DATA,
  CLEAR_ALL_DATA,
  SET_REPORTS,
  SET_VIEWS,
} from "./actions";

const initialState = {
  zAxisDisabled: false,
  allSegmentList: [],
  customSegmentList: [],
  hiddenSegmentIdList: [],
  hiddenCustomSegmentIdList: [],
  brandList: [],
  hiddenBrandIdList: [],
  chartData: [],
  reportsList: [],
  viewsList: [],
};

export default combineEvents(
  {
    [DISABLE_Z_AXIS]: (state) => ({
      ...state,
      zAxisDisabled: true,
    }),
    [ENABLE_Z_AXIS]: (state) => ({
      ...state,
      zAxisDisabled: false,
    }),
    [SET_SEGMENTS]: (state, { segments }) => ({
      ...state,
      allSegmentList: segments,
    }),
    [SET_CUSTOM_SEGMENTS]: (state, { segments }) => ({
      ...state,
      customSegmentList: segments,
    }),
    [DISABLE_SEGMENTS]: (state, { segmentIds }) => ({
      ...state,
      hiddenSegmentIdList: [...segmentIds],
    }),
    [DISABLE_SEGMENT]: (state, { segmentId }) => ({
      ...state,
      hiddenSegmentIdList: [...state.hiddenSegmentIdList, segmentId],
    }),
    [DISABLE_CUSTOM_SEGMENTS]: (state, { customSegmentIds }) => ({
      ...state,
      hiddenCustomSegmentIdList: customSegmentIds,
    }),
    [DISABLE_CUSTOM_SEGMENT]: (state, { customSegmentId }) => ({
      ...state,
      hiddenCustomSegmentIdList: [
        ...state.hiddenCustomSegmentIdList,
        customSegmentId,
      ],
    }),
    [ENABLE_SEGMENT]: (state, { segmentId }) => ({
      ...state,
      hiddenSegmentIdList: state.hiddenSegmentIdList.filter(
        (id) => id !== segmentId
      ),
    }),
    [ENABLE_CUSTOM_SEGMENT]: (state, { customSegmentId }) => ({
      ...state,
      hiddenCustomSegmentIdList: state.hiddenCustomSegmentIdList.filter(
        (id) => id !== customSegmentId
      ),
    }),
    [SET_BRANDS]: (state, { brands }) => ({
      ...state,
      brandList: brands,
    }),
    [DISABLE_BRAND]: (state, { brandId }) => ({
      ...state,
      hiddenBrandIdList: [...state.hiddenBrandIdList, brandId],
    }),
    [ENABLE_BRAND]: (state, { brandId }) => ({
      ...state,
      hiddenBrandIdList: state.hiddenBrandIdList.filter((id) => id !== brandId),
    }),
    [SET_CHART_DATA]: (state, { chartData }) => ({
      ...state,
      chartData,
    }),
    [SET_REPORTS]: (state, { reports }) => ({
      ...state,
      reportsList: reports,
    }),
    [SET_VIEWS]: (state, { views }) => ({
      ...state,
      viewsList: views,
    }),
    [CLEAR_ALL_DATA]: (state) => ({
      viewsList: state?.viewsList,
      initialState
    }),
  },
  initialState
);
