import React, { useMemo } from "react";
import { FaAngleRight } from "react-icons/fa6";

import StyledViewCardFooter from "./Styled.ViewCardFooter";

import FilterIcon from "../../../../assets/icons/icon_filter_gray.svg";
import ViewCardTooltip from "../ViewCardTooltip";
import { getFormattedAgeRangeFilterLabel, getSelectViewURL } from "../utils";

function ViewCardFooter({ filters, segments, view }) {
  function handleSelectView(e) {
    e.stopPropagation();
    const url = getSelectViewURL(view.module, view.screenType, view.viewUrl);
    window.open(url, "_blank");
  }

  const filtersList = useMemo(() => {
    return filters?.reduce((acc, curr) => {
      if (curr?.filterId === 8) {
        const currLabel = getFormattedAgeRangeFilterLabel(curr);
        return [...acc, currLabel];
      } else {
        const result = curr?.filterOptions?.map(
          (item) => item?.filterOptionName
        );
        return [...acc, ...result];
      }
    }, []);
  }, [filters]);

  return (
    <StyledViewCardFooter>
      <div className="segment-and-filter">
        {filtersList?.length > 0 && (
          <ViewCardTooltip contentList={filtersList} offset={[0, -5]}>
            <div className="segments-filter-list">
              <img src={FilterIcon} alt="filters" />
              <span>{filtersList?.length} Filters</span>
            </div>
          </ViewCardTooltip>
        )}
        {segments?.length > 0 && (
          <ViewCardTooltip
            contentList={segments.map((segment) =>
              segment?.segmentName === "Age 18+" &&
              view?.markets[0]?.code?.includes("HSS")
                ? "Age 35+"
                : segment?.segmentName
            )}
            offset={[0, -5]}
          >
            <div className="segments-filter-list">
              <img src={FilterIcon} alt="segments" />
              <span>{segments?.length} Segments</span>
            </div>
          </ViewCardTooltip>
        )}
      </div>
      <div className="select-view" onClick={(e) => handleSelectView(e)}>
        <div>Select view</div>
        <FaAngleRight fontSize={18} />
      </div>
    </StyledViewCardFooter>
  );
}

export default ViewCardFooter;
