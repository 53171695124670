import styled, { css } from "styled-components";
import {
  SECONDARY_BORDER_GRAY_COLOR,
  TEXT_BLACK_LIGHT,
  TEXT_BOLD,
  TEXT_LIGHT,
  TEXT_NORMAL,
} from "../utils/constant";

const StyledHeaderCardBase = css`
  display: flex;
  font-family: "Inter", serif !important;
  width: 100%;
  min-height: 40px;
  align-items: center;
  justify-content: space-between;
  .view-card-drag-icon {
    opacity: 0;
  }
  .header-card-container {
    line-height: 1.5;
    display: flex;
    gap: 10px;
    align-items: center;
    width: 100%;
    .header-card-title-subtitle {
      width: 100%;
    }
  }
  .header-card-icon {
    min-width: 32px;
    min-height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    header-card-checkbox {
      position: absolute;
    }
    img {
      height: 20px;
      width: 20px;
    }
    border: 1px solid ${SECONDARY_BORDER_GRAY_COLOR};
  }
  .header-card-title-with-tooltip,
  .header-card-subtitle-with-tooltip {
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .header-card-title-width-with-icon {
    width: calc(100% - 42px) !important;
  }
  .header-card-container-with-action-button {
    width: calc(100% - 52px);
  }
`;

const StyledHeaderCardPrimary = styled.div`
  ${StyledHeaderCardBase}
  .header-card-title {
    font-size: 14px;
    font-weight: 600;
    color: ${TEXT_BOLD};
  }
  .header-card-subtitle {
    font-size: 11px;
    font-weight: 500;
    color: ${TEXT_LIGHT};
  }
`;

const StyledHeaderCardPrimaryInverted = css`
  ${StyledHeaderCardBase}
  .header-card-container {
    gap: 12px;
  }
  .header-card-title {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 4px;
    color: ${TEXT_BLACK_LIGHT};
  }
  .header-card-subtitle {
    font-size: 14px;
    font-weight: 500;
    color: ${TEXT_BOLD};
  }
`;

const StyledHeaderCardPrimaryInvertedWithIconOutline = styled.div`
  ${StyledHeaderCardPrimaryInverted}
`;

const StyledHeaderCardPrimaryInvertedWithoutIconOutline = styled.div`
  ${StyledHeaderCardPrimaryInverted}
  .header-card-icon {
    border: none;
  }
`;

const StyledHeaderCardPrimaryLarge = styled.div`
  ${StyledHeaderCardBase}
  .header-card-container {
    gap: 14px;
  }
  .header-card-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 4px;
    color: ${TEXT_BOLD};
  }
  .header-card-subtitle {
    font-size: 13px;
    font-weight: 400;
    color: ${TEXT_BLACK_LIGHT};
  }
  .header-card-icon {
    width: 48px;
    height: 48px;
    img {
      height: 24px;
      width: 24px;
    }
  }
`;

const StyledHeaderCardSecondary = styled.div`
  ${StyledHeaderCardBase}
  .header-card-title {
    font-size: 13px;
    font-weight: 400;
    color: ${TEXT_NORMAL};
  }
  .header-card-subtitle {
    font-size: 11px;
    font-weight: 500;
    color: ${TEXT_LIGHT};
  }
`;

export {
  StyledHeaderCardPrimary,
  StyledHeaderCardSecondary,
  StyledHeaderCardPrimaryLarge,
  StyledHeaderCardPrimaryInvertedWithIconOutline,
  StyledHeaderCardPrimaryInvertedWithoutIconOutline,
};
