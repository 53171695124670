import styled from "styled-components";
import { SECONDARY_BORDER_GRAY_COLOR } from "../ViewCard/utils/constant";

const StyledViewActionsCard = styled.div`
  position: absolute;
  left: 40%;
  bottom: 10%;
  width: 273px;
  height: 50px;
  background: #344054;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  align-items: center;

  box-shadow: 0px 5px 15px 0px #00000040, 0px 15px 35px -5px #11182680,
    0px 0px 0px 0.5px #ffffff66;
  .view-action-card {
    height: 32px;
    gap: 20px;
    display: flex;
    align-items: center;
    .content {
      width: 103px;
      font-family: Inter;
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.02em;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #ffffff;
    }
    .actions {
      display: flex;
      align-items: center;
      gap: 15px;
      .actions-button {
        width: 70px;
        height: 32px;
        padding: 6px 20px 6px 12px;
        gap: 15px;
        border-radius: 6px;
        opacity: 0px;
        background: #ffffff;
        box-shadow: 0px 0px 0px 1px #464f6029, 0px 1px 1px 0px #0000001a;
        display: flex;
        align-items: center;
        font-family: Inter;
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.02em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #464f60;
        cursor: pointer;

        .button-conatiner {
          display: flex;
          align-items: center;
          gap: 15px;
        }
      }
    }
    li {
      margin: 0px 5px;
      border-radius: 5px;
    }
    #view-delete-action {
      color: #bf6a02;
      margin: 0px;
      padding: 0px;
      padding-top: 4px;
      margin-top: 4px;
      border-radius: 0px;
      &:hover {
        background-color: white;
      }
      border-top: 1px solid ${SECONDARY_BORDER_GRAY_COLOR};
      .view-delete-action-button {
        margin: 0px 5px;
        width: 100%;
        padding: 10px 10px;
        display: flex;
        gap: 12px;
        align-items: center;
        border-radius: 5px;
        &:hover {
          background-color: #f9fafb;
        }
      }
    }
  }
`;

export { StyledViewActionsCard };
