import React, { useEffect, useRef, useState } from "react";
import "./Views.scss";
import "./DetailModal/detailModal.scss";
import cx from "classnames";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { RxCrossCircled } from "react-icons/rx";
import RequiredIndicator from "../UserAdmin/UsersManagement/RequiredIndicator";
import ApiData from "../../utils/Api";
import { useDispatch } from "react-redux";
import { setReports } from "../../reducers/TwoByTwoChart/actionCreators";
import initData from "../../utils/initData";
import addIcon from "../../assets/icons/icon_views_add.svg";
import searchIcon from "../../assets/icons/icon_modal-search.svg";
import closeIcon from "../../assets/icons/icon_views_close.svg";
import { DateTime } from "luxon";
import ViewCardTooltip from "./ViewCard/ViewCardTooltip";

const CreateEditReport = (props) => {
  const {
    isOpen,
    closeModal,
    selectedAction,
    viewNotification,
    reportId,
    handleToasterMessage = () => {},
  } = props;
  const dispatch = useDispatch();
  const screenIcons = initData.screenIcons();
  const [inputValues, setInputValues] = useState({
    reportId: "",
    reportName: "",
    description: "",
    searchView: "",
    selectedPeriodType: "",
    viewsList: [],
    viewsSearchList: [],
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const containerRef = useRef(null);
  const periodTypes = initData.getPeriodOptions();

  const getSelectedReportData = async () => {
    if (selectedAction === "create") {
      let viewsData = await ApiData.getAllReportViews();
      setInputValues({
        ...inputValues,
        viewsList: [...viewsData],
        viewsSearchList: [...viewsData],
      });
    } else {
      let data = await ApiData.getDetailedReportDetails({
        reportIds: [reportId],
      });
      setInputValues({
        ...inputValues,
        reportId: data?.reportId,
        reportName: data?.reportName,
        description: data?.reportDescription,
        viewsList: data?.views,
        viewsSearchList: data?.views,
      });
    }
  };

  useEffect(() => {
    try {
      getSelectedReportData();
    } catch (err) {
      console.log(err);
    }
  }, [selectedAction, reportId]);

  const handleInputChanges = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  const handleSearch = (e) => {
    let { viewsList } = inputValues;
    const { name, value } = e.target;
    const filteredViewsList = viewsList?.filter(({ viewName }) =>
      viewName.toLowerCase().includes(value.toLowerCase())
    );

    setInputValues({
      ...inputValues,
      viewsSearchList: filteredViewsList,
      [name]: value,
    });
  };

  const clearSearch = () => {
    const { viewsList } = inputValues;
    setInputValues({
      ...inputValues,
      searchView: "",
      viewsSearchList: viewsList,
    });
  };

  const handleSelectViews = (ele) => {
    const updatedViewsList = inputValues.viewsList.map((obj) => {
      if (ele.viewId === obj.viewId) {
        obj.isViewPresentForCurrentReport = !obj.isViewPresentForCurrentReport;
      }
      return obj;
    });
    setInputValues({ ...inputValues, viewsList: updatedViewsList });
  };

  const handleSaveEditReports = async () => {
    try {
      setIsLoading(true);
      const {
        reportName,
        description,
        viewsList,
        selectedPeriodType,
        reportId,
      } = inputValues;

      let payload = {
        name: reportName,
        description: description,
        viewIds: viewsList
          .filter((ele) => ele.isViewPresentForCurrentReport === true)
          ?.map(({ viewId }) => viewId),
      };
      let data;
      console.log(selectedAction, "selectedAction");
      if (selectedAction === "clone" || selectedAction === "create") {
        data = await ApiData.createReport(payload);
        handleToasterMessage({
          type: "success",
          message: "Report has been succesfully created.",
        });
      } else {
        payload = {
          ...payload,
          reportId: reportId,
          // selectedPeriodType: selectedPeriodType?.name,
        };
        data = await ApiData.editReport(payload);
        handleToasterMessage({
          type: "success",
          message: "Report has been succesfully updated.",
        });
      }
      dispatch(setReports([...data]));
      setIsLoading(false);
      closeModal();
    } catch (err) {
      console.log(err);
    }
  };

  const validateFormFields = () => {
    const selectedViews = inputValues?.viewsList?.filter(
      (ele) => ele?.isViewPresentForCurrentReport === true
    );
    return !(inputValues.reportName && selectedViews?.length > 0);
  };

  const isSubmitBtnDisabled = validateFormFields();
  const { reportName, description, searchView, viewsSearchList } = inputValues;

  const handleClose = () => {
    setIsClosing(true); // Start the closing animation
    setTimeout(() => {
      setIsClosing(false);
      closeModal();
    }, 300); // Delay for the animation duration
  };

  return (
    <Modal
      open={isOpen && !isClosing}
      onClose={handleClose}
      center
      closeIcon={<span style={{ display: "none" }} />}
      classNames={{
        root: "views_detail_modal_root",
        modal: "warning-modal views-modal views_detail_modal",
        modalAnimationIn: "react-responsive-modal-from-right-in",
        modalAnimationOut: "react-responsive-modal-from-right-out",
      }}
    >
      <div className="modalHeader">
        <div className="modalHeaderContainer">
          <div className="roundedLogo viewModalLogo">
            <img src={addIcon} alt="" className="warningIcon" />
          </div>
          <div className="modalHeaderContent">
            <div className="modalTitle">{selectedAction} Report</div>
            <div className="modalSubTitle">
              {selectedAction === "edit"
                ? "Edit user report."
                : "Add report to user."}
            </div>
          </div>
        </div>
        <img
          src={closeIcon}
          alt=""
          className="warningIcon closeIcon"
          onClick={handleClose}
        />
      </div>
      <div className="modalContent">
        {!viewNotification && (
          <>
            <div className="form-container form-container-modalContent">
              <div className="form-group">
                <label htmlFor="reportName" className="form-label view-label">
                  Report Name
                  <RequiredIndicator color="black" />
                </label>
                <input
                  className="form-input view-input"
                  type="text"
                  id="reportName"
                  placeholder="Report 2024110101"
                  value={reportName}
                  onChange={handleInputChanges}
                  name="reportName"
                />
              </div>
              <div className="form-group">
                <label htmlFor="description" className="form-label view-label">
                  Description
                </label>
                <input
                  className="form-input view-input"
                  type="text"
                  id="description"
                  placeholder="Enter a Description"
                  value={description}
                  onChange={handleInputChanges}
                  name="description"
                />
              </div>
              <div className="form-group">
                <label htmlFor="slides" className="form-label view-label">
                  Views*
                </label>
                <div className="form-input view-input">
                  <img src={searchIcon} className="iconConatiner" alt="" />
                  <input
                    type="text"
                    id="searchView"
                    placeholder="Search for Views"
                    value={searchView}
                    onChange={handleSearch}
                    name="searchView"
                    style={{
                      width: "85%",
                      border: "none",
                      fontSize: "12px",
                      letterSpacing: "0.25px",
                    }}
                  />
                  {searchView && (
                    <RxCrossCircled
                      className="iconConatiner cursor"
                      onClick={clearSearch}
                    />
                  )}
                </div>
              </div>
              <div
                className={cx(
                  selectedAction !== "create" ? "viewsDataList" : ""
                )}
              >
                <div
                  className={cx("viewsSelectListContainer", {
                    viewsActionsListConatiner: selectedAction !== "create",
                  })}
                  ref={containerRef}
                >
                  {viewsSearchList?.map((ele, ind) => {
                    let logoObj = screenIcons.find(
                      ({ screenType }) => screenType === ele.screenType
                    );
                    return (
                      <div
                        className={cx("viewListConatiner", {
                          active: ele?.isViewPresentForCurrentReport,
                        })}
                        key={ind}
                        onClick={() => handleSelectViews(ele)}
                      >
                        <div className="viewsBox">
                          <div className="viewsContentBoxConatiner">
                            <div className="viewModalroundedLogo">
                              <img
                                src={logoObj?.logo}
                                alt=""
                                className="screenIcon"
                              />
                            </div>
                            <div className="viewListContentContainer">
                              <ViewCardTooltip
                                contentList={[ele?.viewName]}
                                offset={[0, 5]}
                                delay={500}
                              >
                                <div className="header">{ele?.viewName}</div>
                              </ViewCardTooltip>
                              <div className="subHeader">
                                {ele.module}/{ele.screenType}
                              </div>
                              <div className="content">
                                {!(
                                  ele?.periodType === "CUSTOM_RANGE" ||
                                  ele?.periodType === "DYNAMIC_DATE_RANGE"
                                ) ? (
                                  periodTypes?.find(
                                    ({ name }) =>
                                      ele.periodType
                                        ?.replace(/_/g, " ")
                                        ?.toLowerCase() === name?.toLowerCase()
                                  )?.name
                                ) : (
                                  <>
                                    {DateTime.fromFormat(
                                      ele.startDate,
                                      "yyyy-MM-dd"
                                    ).toFormat("MM/dd/yyyy")}{" "}
                                    -{" "}
                                    {DateTime.fromFormat(
                                      ele.endDate,
                                      "yyyy-MM-dd"
                                    ).toFormat("MM/dd/yyyy")}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="viewCheckBoxContainer">
                            <input
                              type="checkbox"
                              id="checkbox"
                              checked={ele?.isViewPresentForCurrentReport}
                              className="viewCheckBox"
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="form-button-actions">
        <div className="cancelButton" onClick={closeModal}>
          Cancel
        </div>
        <div
          className={cx("saveButton", {
            disabled: isSubmitBtnDisabled || isLoading,
          })}
          onClick={handleSaveEditReports}
        >
          Save
        </div>
      </div>
    </Modal>
  );
};

export default CreateEditReport;