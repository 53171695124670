import React, { Component } from 'react';
import moment from 'moment';
import cx from "classnames";
import LoadingLayer from '../widgets/Shared/LoadingLayer';
import Landing from './AdvancedIntelligenceLanding';
import BarChart from '../widgets/ChartComponents/BarChart';
import DifferentiationMap from '../widgets/ChartComponents/DifferentiationMap';
import ButterflyMap from '../widgets/ChartComponents/ButterflyMap';
import SimpleSelect from '../widgets/Shared/SimpleDropDownSelect';
import NoDataBox from '../widgets/Shared/NoDataBox';
import initData from '../../utils/initData';
import ApiData from '../../utils/Api';
import './AdvancedIntelligenceComponent.scss';
import filtersTransformations from '../../utils/filterTransformations';
import { filterHSSMarketTrendsLabels, validationsForHSSMarketLabels } from '../../utils/common';

const stabOptions = [
    {id: 0, name: "Equity"},
    {id: 1, name: "Conversion"},
    {id: 2, name: "Emotional"},
    {id: 3, name: "Advertising"},
];

export default class AdvancedIntelligenceComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            mapData:[],
            zoomLevel: 0,
            zThreshold: 0,
            sampleSize: {},
            loading: true,
            readyShow: true,
            changeMulti: false,
            optionOpen: false,
        }
    }

    afterUpdating = () => {
        const { mapData, sampleSize } = this.state
        this.props.setDataAvailability(mapData.length)
        this.props.sendSsize(sampleSize)
    }

    getListData = () => {
        const{brands, selectedTab, selectedBrand, selectedPctBrand, selectedDate, prePostLineOption, useExposed, selectedCampaign, page, segments, statTestingEnabled, getAppliedFilters} = this.props;
        let optionModes = initData.getModes();
        let brandIds = [];
        let selectedMode = optionModes.filter(obj=> obj.id === useExposed)[0]?.name?.toUpperCase().replace(/ /g, "");
        
        brands.map(brandObj=>brandIds.push(brandObj.id))
        let [_, ...competitors] = selectedBrand.map((id) => {
            const selectBrnd = brands[id];
            return selectBrnd?.id;
        });
        let searchFilter = getAppliedFilters();
        let selectedFilters = filtersTransformations.applyMarketFilters(searchFilter, segments, selectedCampaign);

        if (Number(selectedTab) === 0 ) {
            let body = {
                marketId: selectedCampaign.marketId,
                brandIds: [brands[selectedBrand[0]]?.id],
                competitors: competitors,
                fromDate: selectedDate.startdate,
                toDate: selectedDate.enddate,
                filters: selectedFilters[0]?.filters,
                segments: selectedFilters[0]?.segments,
                audienceType: 'FAMILIAR',
                isStatTestingEnabled: statTestingEnabled
            }
            ApiData.getGeoBrandDifferentiationData(body).then(
                (result) => {
                    let characteristics = filterHSSMarketTrendsLabels(selectedCampaign?.countryCode, result.characteristics)
                    this.setState({
                        mapData: characteristics,
                        sampleSize: result.pool,
                        loading: false,
                        readyShow: true
                    }, this.afterUpdating)
                }
            ).catch(
                (err) => {
                    console.log("error=" + err );
                    this.props.setDataAvailability(false)
                    this.setState({
                        mapData: [],
                        sampleSize: [],
                        loading: false,
                        readyShow: true
                    });
                }
            );
        } else if (Number(selectedTab) === 1 ) {
            let selectedBrandIds = selectedPctBrand.map(si=>{return brands[si]?.id})
            let competitors = selectedBrandIds.filter(id=>Number(id) !== Number(selectedBrandIds[0]))
            let payload = {
                marketId: selectedCampaign.marketId,
                brandIds: [selectedBrandIds[0]],
                competitors: competitors,
                fromDate: selectedDate.startdate,
                toDate: selectedDate.enddate,
                filters: selectedFilters[0]?.filters,
                segments: selectedFilters[0]?.segments,                
                audienceType: 'FAMILIAR',
                isStatTestingEnabled: statTestingEnabled
            }
            ApiData.getGeoBrandPerceptualData(payload).then(
                (result) => {
                    let characteristics = filterHSSMarketTrendsLabels(selectedCampaign?.countryCode, result.characteristics)
                    this.setState({
                        mapData: [...result.brands.map(bdata=>{return { ...{brand:true}, ...bdata}}), ...characteristics],
                        zoomLevel: result.zoomLevel,
                        sampleSize: result.pool,
                        loading: false,
                        readyShow: true,
                    }, this.afterUpdating)
                }
            ).catch(
                (err) => {
                    console.log("error=" + err );
                    this.props.setDataAvailability(false)
                    this.setState({
                        mapData: [],
                        zoomLevel: 0,
                        sampleSize: [],
                        loading: false,
                        readyShow: true
                    });
                }
            );
        }
        else if (Number(selectedTab) === 2 ) {
            let payload = {
                marketId: selectedCampaign.marketId,
                brandIds: [brands[selectedBrand[0]]?.id],
                competitors:[brands[selectedBrand[1]]?.id],
                fromDate: selectedDate.startdate,
                toDate: selectedDate.enddate,
                filters: selectedFilters[0]?.filters,
                segments: selectedFilters[0]?.segments,                
                audienceType: selectedMode,
                isStatTestingEnabled: statTestingEnabled
            }
            ApiData.getGeoBrandButterflyData(payload).then(
                (result) => {
                    let characteristics = filterHSSMarketTrendsLabels(selectedCampaign?.countryCode, result.characteristics)
                    this.setState({
                        mapData: characteristics,
                        sampleSize: result.pool,
                        loading: false,
                        readyShow: true,
                    }, this.afterUpdating)
                }
            ).catch(
                (err) => {
                    console.log("error=" + err );
                    this.props.setDataAvailability(false)
                    this.setState({
                        mapData: [],
                        sampleSize: [],
                        loading: false,
                        readyShow: true,
                    })
                }
            );
        } else if (Number(selectedTab) === 3 ) {
            let startdate = (moment(selectedDate.startdate).isBefore('2022-09-21') && selectedDate.selectedPeriod == 1 && prePostLineOption.stab === 3) ? '2022-09-21':selectedDate.startdate;
            let pstartdate = (moment(selectedDate.pstartdate).isBefore('2022-09-21') && selectedDate.selectedPeriod == 1 && prePostLineOption.stab === 3) ? '2022-09-21':selectedDate.pstartdate;
            let groupValue = stabOptions.filter(val=>val.id === prePostLineOption.stab)

            const payload = {
                marketId: selectedCampaign.marketId,
                brandIds:[brands[selectedBrand[0]].id],
                filters: selectedFilters[0]?.filters,
                segments: selectedFilters[0]?.segments,                
                audienceType: selectedMode,
                module: initData.getModuleType(page),
                isStatTestingEnabled: statTestingEnabled
            };
            payload.prepost = {
                preFromDate:moment(startdate).format('YYYY-MM-DD'),
                preToDate:moment(selectedDate.enddate).format('YYYY-MM-DD'),
                postFromDate:moment(pstartdate).format('YYYY-MM-DD'),
                postToDate:moment(selectedDate.penddate).format('YYYY-MM-DD'),
                group:groupValue[0].name.toLowerCase()
            }
            const brandpre = {id: 1, name:'PRE'};
            const brandpost = {id: 2, name:'POST'};
            const preColor = "#081D58";
            const postColor = "#41B6C4";
            if (prePostLineOption.stab===0) {
                ApiData.getGeoBrandPrePostData(payload).then(
                    (result) => {
                        const mapData = [];
                        const sampleSize = [];
                        if (result) {
                            const pre_data= [
                                {label: "Equity", value: Number(result.pre.equity ? result.pre.equity?.toFixed(1) : 0)},
                                {label: "Momentum", value: Number(result.pre.netMomentum ? result.pre.netMomentum?.toFixed(1) : 0)},
                                {label: "Consideration", value: Number(result.pre.consideration ? result.pre.consideration?.toFixed(1): 0)},
                                {label: "Quality", value: Number(result.pre.highQuality ? result.pre.highQuality?.toFixed(1) : 0)},
                                {label: "Familiarity", value: Number(result.pre.familiarity ? result.pre.familiarity?.toFixed(1) : 0)}
                            ];
                            const post_data= [
                                {label: "Equity", value: Number(result.post.equity ? result.post.equity?.toFixed(1): 0), zValue: Number(result.zFields.equity?.toFixed(1))},
                                {label: "Momentum", value: Number(result.post.netMomentum ? result.post.netMomentum?.toFixed(1) : 0), zValue: Number(result.zFields.netMomentum?.toFixed(1))},
                                {label: "Consideration", value: Number(result.post.consideration ? result.post.consideration?.toFixed(1) : 0), zValue: Number(result.zFields.consideration?.toFixed(1))},
                                {label: "Quality", value: Number(result.post.highQuality ? result.post.highQuality?.toFixed(1) : 0), zValue: Number(result.zFields.highQuality?.toFixed(1))},
                                {label: "Familiarity", value: Number(result.post.familiarity ? result.post.familiarity?.toFixed(1): 0), zValue: Number(result.zFields.familiarity?.toFixed(1))}
                            ];
                            const preData = {
                                pool: result.pre.pool,
                                value: Number(result.pre.equity ? result.pre.equity?.toFixed(1): 0),
                                // upDown: Number(result.pre.equityDiff?.toFixed(1)),
                                data: pre_data
                            }
                            const postData = {
                                pool: result.post.pool,
                                value: Number(result.post.equity ? result.post.equity?.toFixed(1): 0),
                                // upDown: Number(result.post.equityDiff?.toFixed(1)),
                                data: post_data
                            }
                            mapData.push({brand:brandpre, tab: 0, color: preColor, data: preData});
                            mapData.push({brand:brandpost, tab: 0, color: postColor, data: postData});
                            sampleSize.push(result.pre.pool);
                            sampleSize.push(result.post.pool);
                        }
                        this.setState({
                            mapData,
                            sampleSize,
                            zThreshold: result.zThreshold,
                            loading: false,
                            readyShow: true
                        }, this.afterUpdating)
                    }
                ).catch(
                    (err) => {
                        console.log("error=" + err );
                        this.props.setDataAvailability(false)
                        this.setState({
                            mapData: [],
                            sampleSize: [],
                            loading: false,
                            readyShow: true,
                        })
                    }
                );
            } else if (prePostLineOption.stab===1) {
                payload.audienceType="GENPOP"
                ApiData.getGeoBrandPrePostData(payload).then(
                    (result) => {
                        const mapData = [];
                        const sampleSize = [];
                        if (result) {
                            const pre_data= [
                                {label: 'Awareness', value: Number(result.pre.awareness ? result.pre.awareness?.toFixed(1) : 0)},
                                {label: 'Familiarity', value: Number(result.pre.familiarity ? result.pre.familiarity?.toFixed(1) : 0)},
                                {label: 'Trial', value: Number(result.pre.trial ? result.pre.trial?.toFixed(1) : 0)},
                                {label: 'Usage', value: Number(result.pre.usage ? result.pre.usage?.toFixed(1):0)},
                                {label: 'Recommend', value: Number(result.pre.recommend ? result.pre.recommend?.toFixed(1) : 0)}
                            ];
                            const post_data= [
                                {label: 'Awareness', value: Number(result.post.awareness ? result.post.awareness?.toFixed(1) : 0), zValue: Number(result.zFields.awareness?.toFixed(1))},
                                {label: 'Familiarity', value: Number(result.post.familiarity ? result.post.familiarity?.toFixed(1) : 0), zValue: Number(result.zFields.familiarity?.toFixed(1))},
                                {label: 'Trial', value: Number(result.post.trial ? result.post.trial?.toFixed(1) : 0), zValue: Number(result.zFields.trial?.toFixed(1))},
                                {label: 'Usage', value: Number(result.post.usage ? result.post.usage?.toFixed(1) : 0), zValue: Number(result.zFields.usage?.toFixed(1))},
                                {label: 'Recommend', value: Number(result.post.recommend ? result.post.recommend?.toFixed(1) : 0), zValue: Number(result.zFields.recommend?.toFixed(1))}
                            ];

                            const preData = {
                                pool: result.pre.pool,
                                data: pre_data
                            }
                            const postData = {
                                pool: result.post.pool,
                                data: post_data
                            }
                            mapData.push({brand:brandpre, tab: 1, color: preColor, data: preData});
                            mapData.push({brand:brandpost, tab: 1, color: postColor, data: postData});
                            sampleSize.push(result.pre.pool);
                            sampleSize.push(result.post.pool);
                        }
                        this.setState({
                            mapData,
                            sampleSize,
                            zThreshold: result.zThreshold,
                            loading: false,
                            readyShow: true
                        }, this.afterUpdating)
                    }
                ).catch(
                    (err) => {
                        console.log("error=" + err );
                        this.props.setDataAvailability(false)
                        this.setState({
                            mapData: [],
                            sampleSize: [],
                            loading: false,
                            readyShow: true,
                        })
                    }
                );
            } else if (prePostLineOption.stab===2) {
                ApiData.getGeoBrandPrePostData(payload).then(
                    (result) => {
                        const mapData = [];
                        const preSort = [];
                        const sampleSize = [];
                        const preData = validationsForHSSMarketLabels(result.pre, selectedCampaign?.countryCode);
                        const postData = validationsForHSSMarketLabels(result.post, selectedCampaign?.countryCode, result.zFields);

                        postData.sort((a,b)=>{return b.value-a.value});
                        postData.forEach(post=>{
                            preSort.push(preData.find((pre)=>pre.label===post.label))
                        });

                        mapData.push({brand:brandpre, tab: 2, color: preColor, data: {pool: result.pre.pool, data:preSort} });
                        mapData.push({brand:brandpost, tab: 2, color: postColor, data: {pool: result.post.pool, data:postData} });
                        sampleSize.push(result.pre.pool);
                        sampleSize.push(result.post.pool);

                        this.setState({
                            mapData,
                            sampleSize,
                            zThreshold: result.zThreshold,
                            loading: false,
                            readyShow: true
                        }, this.afterUpdating)
                    }
                ).catch(
                    (err) => {
                        console.log("error=" + err );
                        this.props.setDataAvailability(false)
                        this.setState({
                            mapData: [],
                            sampleSize: [],
                            loading: false,
                            readyShow: true,
                        })
                    }
                );
            } else if (prePostLineOption.stab===3) {
                if(moment(selectedDate.startdate).isBefore('2022-09-21')&& Number(selectedDate.selectedPeriod) === 1 && Number(prePostLineOption.stab) === 3){
                    payload.preFromDate = '2022-09-21'
                }
                if(moment(selectedDate.pstartdate).isBefore('2022-09-21')&& Number(selectedDate.selectedPeriod) === 1 && Number(prePostLineOption.stab) === 3){
                    payload.postFromDate = '2022-09-21'
                }
                ApiData.getGeoBrandPrePostData(payload).then(
                    (result) => {
                        const mapData = [];
                        const preSort = [];
                        const sampleSize = [];
                        const preData = validationsForHSSMarketLabels(result.pre, selectedCampaign?.countryCode);
                        const postData = validationsForHSSMarketLabels(result.post, selectedCampaign?.countryCode, result.zFields);

                        postData.sort((a,b)=>{return b.value-a.value});
                        postData.forEach(post=>{
                            preSort.push(preData.find((pre)=>pre.label===post.label))
                        });
                        mapData.push({brand:brandpre, tab: 3, color: preColor, data: {pool: result.pre.pool, data:preSort} });
                        mapData.push({brand:brandpost, tab: 3, color: postColor, data: {pool: result.post.pool, data:postData} });
                        sampleSize.push(result.pre.pool);
                        sampleSize.push(result.post.pool);
                        this.setState({
                            mapData,
                            sampleSize,
                            zThreshold: result.zThreshold,
                            loading: false,
                            readyShow: true
                        }, this.afterUpdating)
                    }
                ).catch(
                    (err) => {
                        console.log("error=" + err );
                        this.props.setDataAvailability(false)
                        this.setState({
                            mapData: [],
                            sampleSize: [],
                            loading: false,
                            readyShow: true,
                        })
                    }
                );
            }
        } else {
            this.setState({
                mapData: [],
                sampleSize: [],
                loading: false,
                readyShow: true
            })
        }
    }

    onSelectStab(index) {
        if (typeof this.props.onChangeLineOption === 'function') {
          this.props.onChangeLineOption({
            stab: index,
            selectedOption: 'total'
          });
        }
        this.setState({
            optionOpen: '',
        });
    }

    render() {
        const { mapData, loading, readyShow, optionOpen, zoomLevel, zThreshold } = this.state;
        const { selectedTab, selectedBrand, brands, prePostLineOption, statTestingEnabled, menuExpand } = this.props;
        const bcolors = initData.getColors().map(c=>{return c[0]});
        const selectedBrandName = brands[selectedBrand[0]]?.displayName ? brands[selectedBrand[0]]?.displayName : brands[selectedBrand[0]]?.name;
        const brandLogo = (brands[selectedBrand[0]]?.logo) ?
                            <img src={brands[selectedBrand[0]].logo} className="logoIcon" alt={brands[selectedBrand[0]]?.name} /> :
                            (<div className="logoIcon">
                                <div>{selectedBrandName?.slice(0, 1)?.toUpperCase()}</div>
                            </div>);
        const noData = mapData.length === 0;
        return (
            <div className='advancedAnalyticsMainContainer'>
                <div className={cx("advancedAnalyticContainer", {"loading":loading, "prePost":selectedTab===3, "landingPage": selectedTab<0})}>
                    {loading && <LoadingLayer />}
                    {(selectedTab === 0 && !noData) &&
                        <div className="axisTitle yAxis">
                            <span>
                                <span style={{color:"#0062FF"}}>{brands[selectedBrand[0]].displayName ? brands[selectedBrand[0]].displayName : brands[selectedBrand[0]].name}</span>
                                <span> / </span>
                                <span>Competitors</span>
                            </span>
                        </div>
                    }
                    {selectedTab === -1 ?
                    <Landing onPageClick = {this.props.onTabClick} /> :
                    <div className={cx("mapContainer", {"noYaxis": selectedTab > 0 })}>
                        {selectedTab === 0 && mapData.length>0 && readyShow && <DifferentiationMap mapData={mapData} showXY={true} />}
                        {selectedTab === 1 && mapData.length>0 && readyShow && <DifferentiationMap mapData={mapData} zoomlevel={zoomLevel} brands={brands} perceptual="perceptual" />}
                        {selectedTab === 2 && mapData.length>0 && brands.length > 2 && readyShow && <ButterflyMap mapData={mapData} colors={[bcolors[selectedBrand[0] % 10], bcolors[selectedBrand[1] % 10]]}
                        logo={brandLogo} brandName={[brands[selectedBrand[0]].displayName ? brands[selectedBrand[0]].displayName : brands[selectedBrand[0]].name, brands[selectedBrand[1]].displayName ? brands[selectedBrand[1]].displayName : brands[selectedBrand[1]].name]}
                        statTestingEnabled={statTestingEnabled} 
                        />}
                        {selectedTab === 3 && <div className="selectContainer">
                            <SimpleSelect data={stabOptions} selected={prePostLineOption.stab} open={optionOpen==='stab'} placeholder="" skey="c_" onOpenSelect={()=>this.setState( {optionOpen: optionOpen==='stab'? '' : 'stab' })} onSelectFunction = {this.onSelectStab.bind(this)} />
                        </div>}
                        {selectedTab === 3 && readyShow && mapData.length>0 && <BarChart key='barChart' data={mapData} zThreshold={zThreshold} listNotShow = {true} selectedTab={selectedTab} selectedPrepostStab={prePostLineOption.stab} page='advanced' menuExpand={menuExpand}/>}
                        {(selectedTab === 0 || (selectedTab === 2 && brands.length>2)) &&
                            <div
                                className={cx(
                                    "axisTitle xAxis",
                                    {
                                        "hidden": noData && (selectedTab === 0 || selectedTab === 2),
                                    },
                                )}
                            >
                                <div className={'labelBlock'}>
                                    <span style={{color:"#0062FF"}}>{brands.length > 2 && (brands[selectedBrand[0]]?.displayName ? brands[selectedBrand[0]]?.displayName + ' ' : brands[selectedBrand[0]]?.name + ' ')}</span>
                                    <span>/ </span>
                                    <span>{selectedTab<2? 'Self' : brands[selectedBrand[1]].displayName ? brands[selectedBrand[1]].displayName : brands[selectedBrand[1]].name}</span>
                                </div>
                            </div>
                        }
                        {(!mapData.length) && <NoDataBox />}
                    </div>
                    }
                </div>
            </div>
        )
    }
}