import React from "react";
import StyledButtonWithDropdownOption from "./Styled.ButtonWithDropdownOption";

function ButtonWithDropdownOption({
  placement = "bottom-end",
  children,
  trigger,
  closeOnContentClick = true,
  handleOutsideClick = () => {},
  offset = [],
  className = "",
}) {
  const [tippyRef, setTippyRef] = React.useState(null);

  const handleCloseTippy = () => {
    if (tippyRef) {
      tippyRef.hide();
    }
  };

  const handleDropdownClick = (event) => {
    event.stopPropagation();
    if (closeOnContentClick) {
      handleCloseTippy();
    }
  };
  
  return (
    <StyledButtonWithDropdownOption
      interactive={true}
      trigger="click"
      zIndex={900}
      className={className}
      onClickOutside={handleOutsideClick}
      arrow={false}
      offset={offset}
      placement={placement}
      content={
        <div onClick={handleDropdownClick}>
          {typeof children === "function"
            ? children(handleCloseTippy)
            : children}
        </div>
      }
      onCreate={(instance) => setTippyRef(instance)}
    >
      {trigger}
    </StyledButtonWithDropdownOption>
  );
}

export default ButtonWithDropdownOption;
