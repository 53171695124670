import React, { Component } from 'react'
import cx from 'classnames'
import moment from 'moment';
import initData from '../../utils/initData'
import Tabs from './Tabs';
import collapse from '../../assets/icons/icon_menu_collapse.svg'
import expand from '../../assets/icons/icon_menu_expand.svg'
import ddIcon from '../../assets/icons/icon_arrow_down.svg'
import './Menu.scss'
import Drift from '../widgets/Drift.jsx';
import { getSessionStorage } from '../../utils/storageHelpers';

export default class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = { tabsShow: this.props.currentPage }
    } 
    onMenuClick = async(menuTab, menuPath) => {
        this.setState( {
            tabsShow: menuPath   
        },await this.props.onMenuClick(menuTab, '/'+menuPath));
    }
    render() {
        const {currentPage, menuClass, selectedTab, menuExpand, authorizedType} = this.props;
        const {tabsShow} = this.state;
        let menuShow = [];
        const defaultSideMenuEnabled = JSON.parse(getSessionStorage('sideMenu'))
        const menus = initData.getMenu();
        for( let menuPath in menus) {
            const menuItem = menus[menuPath];
            const menuTab = menuPath==='advanced'? -1 : 0;
            if (menuItem.authorizedType===authorizedType && menuItem.menuTitle!=='hidden') {
                menuShow.push(
                    <div key={menuPath} className={cx("menuContainer", { "active": currentPage === menuPath, "opened": menuPath===tabsShow } )}>
                        <div className={cx("icon", { "active": currentPage === menuPath })} onClick={(currentPage !== menuPath || currentPage === 'users') ? this.onMenuClick.bind(this, menuTab, menuPath, false):null}>
                            <img src={currentPage === menuPath ? menuItem.iconCurrent : menuItem.icon} className="image" alt={menuPath} />
                        </div>
                        <div className={cx("menuText", { "active": currentPage === menuPath })} onClick={(currentPage !== menuPath || currentPage === 'users')?this.onMenuClick.bind(this, menuTab, menuPath, false):null}>
                            {menuItem.menuTitle}
                        </div>
                        {(menuExpand || defaultSideMenuEnabled) && authorizedType==='USER' &&
                            <div className="tabIcon">
                                {menuItem.tabs.length > 0  && 
                                    <img className={ menuPath===tabsShow ? 'ddArrowOpen' : 'ddArrow'} 
                                        src={ddIcon} alt="" 
                                        onClick={() => this.setState({tabsShow: menuPath===tabsShow ? '' : menuPath })} />
                                }
                            </div>
                        }
                        {(menuExpand || defaultSideMenuEnabled) && menuItem.tabs.length > 0  && menuPath===tabsShow && authorizedType==='USER' &&
                            <Tabs inMenu={true} tabs={menuItem.tabs} currentTab={currentPage === menuPath ? selectedTab : -1} onClickTabItem={(index) => this.onMenuClick(index, menuPath, true)} />
                        }
                    </div>
                )
            }    
        }
        return (
            <div className={menuClass}>
                <div className="topContainer">
                    {menuShow}
                </div>
                <div className="bottomContainer">
                    <div className="copyright">&copy; Harris Poll QuestBrand is Copyright of The Harris Poll {moment().format('YYYY')} - {moment().add(1, 'y').format('YYYY')}. All Rights Reserved.</div>
                    <img onClick={() => this.props.onExpandClick()} className="iconExpand" src={expand} alt="expand" />
                    <img onClick={() => this.props.onExpandClick()} className="iconCollapse" src={collapse} alt="collapse" />
                </div>
            </div>
        )
    }
}
