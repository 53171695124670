import lookup from "country-code-lookup";
import EquityIcon from "../../../../assets/icons/icon_views_equity_black.svg";
import ConversionIcon from "../../../../assets/icons/icon_views_conversion_black.svg";
import TrendsIcon from "../../../../assets/icons/icon_views_trends_black.svg";
import EmotionalIcon from "../../../../assets/icons/icon_views_emotional_black.svg";
import AdvertisingIcon from "../../../../assets/icons/icon_views_advertising_black.svg";
import ProfileIcon from "../../../../assets/icons/icon_views_profile_black.svg";
import DifferentiationIcon from "../../../../assets/icons/icon_views_differentiation_black.svg";
import PerceptualIcon from "../../../../assets/icons/icon_views_perceptual_black.svg";
import ButterflyIcon from "../../../../assets/icons/icon_views_butterfly_black.svg";
import PrepostIcon from "../../../../assets/icons/icon_views_prepost_black.svg";
import { DateTime } from "luxon"; 

const MODULE_DISPLAY_MAPPING = {
  DETAILED: "Segments",
  SUMMARY: "Summary",
  COMPETITIVE: "Competitive",
  GLOBAL: "Global",
  ADVANCED: "Advanced",
};

const PERIOD_DISPLAY_NAME_MAPPING = {
  CUSTOM: "Custom",
  LAST_180_DAYS: "Last 180 days",
  LAST_90_DAYS: "Last 90 days",
  LAST_30_DAYS: "Last 30 days",
  YEAR_TO_DATE: "Year to date",
  LAST_7_DAYS: "Last 7 days",
};

function getModuleDisplayName(module) {
  return MODULE_DISPLAY_MAPPING[module];
}

function getPeriodDisplayName(period) {
  return PERIOD_DISPLAY_NAME_MAPPING[period];
}

const SCREEN_TYPE_DETAIL = {
  SUMMARY: {
    screenType: "SUMMARY",
    displayName: "Summary",
    icon: EquityIcon,
  },
  EQUITY: {
    screenType: "EQUITY",
    displayName: "Equity",
    icon: EquityIcon,
  },
  CONVERSION: {
    screenType: "CONVERSION",
    displayName: "Conversion",
    icon: ConversionIcon,
  },
  TRENDS: {
    screenType: "TRENDS",
    displayName: "Trends",
    icon: TrendsIcon,
  },
  EMOTIONAL: {
    screenType: "EMOTIONAL",
    displayName: "Emotional",
    icon: EmotionalIcon,
  },
  ADVERTISING: {
    screenType: "ADVERTISING",
    displayName: "Advertising",
    icon: AdvertisingIcon,
  },
  PROFILE: {
    screenType: "PROFILE",
    displayName: "Profile",
    icon: ProfileIcon,
  },
  ALL: {
    screenType: "ALL",
    displayName: "All",
    icon: EquityIcon,
  },
  DIFFERENTIATION: {
    screenType: "DIFFERENTIATION",
    displayName: "Differentiation",
    icon: DifferentiationIcon,
  },
  BUTTERFLY: {
    screenType: "BUTTERFLY",
    displayName: "Butterfly",
    icon: ButterflyIcon,
  },
  PERCEPTUAL: {
    screenType: "PERCEPTUAL",
    displayName: "Perceptual",
    icon: PerceptualIcon,
  },
  PREPOST: {
    screenType: "PREPOST",
    displayName: "Pre post",
    icon: PrepostIcon,
  },
};

function getScreenTypeDetail(screenType) {
  return SCREEN_TYPE_DETAIL[screenType];
}

function getCountryCodeWithMarketName(market) {
  let countryCode = lookup.countries.filter(
    (obj) =>
      obj?.country === market?.displayName || obj?.internet === market?.code
  );
  return (countryCode = countryCode[0]?.internet
    ? countryCode[0]?.internet.toLowerCase()
    : market?.code.startsWith("HSS")
    ? "us"
    : countryCode[0]?.internet);
}

function getSelectViewURL(module, screenType, url) {
  const viewUrl = new URL(
    `${window.location.protocol}//${
      window.location.host
    }/${MODULE_DISPLAY_MAPPING[
      module
    ]?.toLowerCase()}/${screenType?.toLowerCase()}${url}`
  );
  return viewUrl;
}

function getFormattedAgeRangeFilterLabel(filter) {
  const filterOptionsIds = filter?.filterOptions
    ?.map((item) => item?.filterOptionId)
    ?.sort();
  if (filterOptionsIds?.length > 1) {
    return `Age : ${filterOptionsIds[0]}-${filterOptionsIds[1]}`;
  }
}

function calculateDays(periodType) {
  let defaultStartDate;
  switch (periodType) {
    case "LAST_7_DAYS":
      defaultStartDate = DateTime.now()
        .minus({ days: 6 })
        .toFormat("yyyy-MM-dd");
      break;
    case "LAST_30_DAYS":
      defaultStartDate = DateTime.now()
        .minus({ days: 30 })
        .toFormat("yyyy-MM-dd");
      break;
    case "LAST_90_DAYS":
      defaultStartDate = DateTime.now()
        .minus({ days: 90 })
        .toFormat("yyyy-MM-dd");
      break;
    case "YEAR_TO_DATE":
      const currentDate = DateTime.now();
      const startingDateOfYear = currentDate.startOf("year");
      defaultStartDate = startingDateOfYear.toFormat("yyyy-MM-dd");
      break;
    case "LAST_180_DAYS":
      defaultStartDate = DateTime.now()
        .minus({ days: 180 })
        .toFormat("yyyy-MM-dd");
      break;
    default:
      break;
  }
  return defaultStartDate;
}

function getUpdatedViewDetails(view) {
  if (
    view?.periodType !== "DYNAMIC_DATE_RANGE" &&
    view?.periodType !== "CUSTOM_RANGE"
  ) {
    view.startDate = calculateDays(view?.periodType);
    view.endDate = DateTime.now().minus({ days: 1 }).toFormat("yyyy-MM-dd");
  }
  if (
    view?.secondaryPeriodType !== "DYNAMIC_DATE_RANGE" &&
    view?.secondaryPeriodType !== "CUSTOM_RANGE"
  ) {
    view.secondaryStartDate = calculateDays(view?.secondaryPeriodType);
    view.secondaryEndDate = DateTime.now()
      .minus({ days: 1 })
      .toFormat("yyyy-MM-dd");
  }

  return view;
}

export {
  getModuleDisplayName,
  getPeriodDisplayName,
  getScreenTypeDetail,
  getUpdatedViewDetails,
  getCountryCodeWithMarketName,
  getSelectViewURL,
  getFormattedAgeRangeFilterLabel,
};
