import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import ApiData from "./utils/Api";
import LoadingLayer from "./components/widgets/Shared/LoadingLayer";
import {
  getLocalStorage,
  setLocalStorage,
  setSessionStorage,
} from "./utils/storageHelpers";
import verifyExistingUserWithoutContracts from "./utils/verifyExistingUserWithoutContracts";
import { safeParseJSONSessionStorage } from "./utils/getBrowserStorageData";
import { getURLPathParams, isInvalidValue } from "./utils/URLParamsValidations";
import { STORAGE_KEY } from "./constants/app/storage";
import {
  generateWSClientId,
  triggerWSClientIdUpdateEvent,
} from "./utils/userHelper";
import initData from "./utils/initData";
let userDashboardMenus = [
  "summary",
  "segments",
  "competitive",
  "global",
  "advanced",
  "userdashbord",
  "reports",
];

class AuthCheckWrapper extends Component {
  constructor(props) {
    super(props);
    this.authorizedType = getLocalStorage("authorizedType");
    const is_logged_in = getLocalStorage("token", { doNotParse: false });
    const myBrands = JSON.parse(getLocalStorage("myBrands"));
    const redirectTo =
      this.authorizedType === "ADMIN"
        ? "/users"
        : myBrands && myBrands.length > 0
        ? "/summary"
        : "/selectbrand";
    this.state = {
      isLogin: !!is_logged_in,
      redirectTo,
      selectedCamapaignIdentity: null,
    };
  }

  async componentDidMount() {
    try {
      const { isLogin } = this.state;
      const { search } = this.props.location;
      if (!isLogin) {
        var data = this.parseQuery(search);
        if (data && data.t) {
          try {
            let urlPath = safeParseJSONSessionStorage("redirectURL");
            setSessionStorage("sideMenu", true);
            setLocalStorage("token", data.t);
            let result = await ApiData.getUserInfo();
            const authUserId = result.user.id;
            setLocalStorage("userInfo", JSON.stringify(result.user));
            setLocalStorage("authorizedType", "USER");
            setLocalStorage("markets", JSON.stringify(result.marketGroups));
            setLocalStorage("globalBrands", JSON.stringify(result.brands));
            if (result?.defaultUserView?.viewId) {
              let moduleTypes = initData.getModule();
              setSessionStorage(
                "params",
                JSON.stringify(result.defaultUserView?.viewUrl)
              );
              setSessionStorage(
                "redirectURL",
                JSON.stringify(
                  `/${result.defaultUserView?.module.toLowerCase()}/${result.defaultUserView?.screenType.toLowerCase()}`
                )
              );
              urlPath = `/${moduleTypes
                ?.find(({ name }) => name === result.defaultUserView?.module)
                ?.displayName?.toLowerCase()}/${result.defaultUserView?.screenType.toLowerCase()}`;
            }
            let selectedMarket = this.getSelectedCampaign(result.marketGroups);
            let brands =
              selectedMarket.marketId &&
              (await ApiData.getUserBrandsByCampaign(
                result.user.id,
                selectedMarket.marketId
              ));
            setLocalStorage(
              "myBrands",
              JSON.stringify(brands && Array.isArray(brands) ? brands : [])
            );
            setLocalStorage(
              STORAGE_KEY.WS_CLIENT_ID,
              generateWSClientId(authUserId)
            );
            triggerWSClientIdUpdateEvent(generateWSClientId(authUserId));
            this.authorizedType = "USER";
            let redirectTo = "";
            if (brands.length > 0) {
              redirectTo = urlPath !== null ? urlPath : "/";
            } else {
              redirectTo = "/selectbrand";
            }
            //Set the authorized type and redirectTo based on redirectURL from localstorage
            let matchDashboardPath = userDashboardMenus.some((path) =>
              urlPath?.includes(path)
            );
            if (matchDashboardPath || urlPath === "/") {
              setLocalStorage("authorizedType", "USER");
              this.authorizedType = "USER";
            } else if (result.user.userType !== "USER") {
              setLocalStorage("authorizedType", "ADMIN");
              this.authorizedType = "ADMIN";
            } else {
              redirectTo = "/selectbrand";
            }
            this.setState({
              isLogin: true,
              redirectTo: redirectTo,
            });
          } catch (err) {
            if (
              JSON.parse(getLocalStorage("userInfo")) &&
              getLocalStorage("token", { doNotParse: false })
            )
              this.setState({
                isLogin: true,
                redirectTo: "/selectbrand",
              });
          }
        } else {
          var params = {
            completeUrl: process.env.REACT_APP_AUTH0_COMPLETE_URL + "/",
            failUrl:
              process.env.REACT_APP_AUTH0_COMPLETE_URL + "/register/fail",
            serverLogin: "true",
          };
          let path =
            window.location.pathname === "/"
              ? "/summary"
              : window.location.pathname;

          //store path and default parameters in local storage for redirection
          setSessionStorage("params", JSON.stringify(window.location.search));
          setSessionStorage("redirectURL", JSON.stringify(path));
          window.location = this.makeUrl(
            process.env.REACT_APP_USER_URL,
            "/auth/login",
            params
          );
        }
      }
    } catch (error) {
      console.log("error=" + error);
    }
  }

  parseQuery(queryString) {
    var query = {};
    var pairs = (
      queryString[0] === "?" ? queryString.substr(1) : queryString
    ).split("&");
    for (var i = 0; i < pairs.length; i++) {
      var pair = pairs[i].split("=");
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
    }
    return query;
  }

  getSelectedCampaign = (markets) => {
    let market = getURLPathParams()?.market;
    let selectedMarket = [];
    markets?.length > 0 &&
      markets.forEach(({ marketGroupsList }) => {
        let findSelectedMarket = marketGroupsList.find(
          ({ identity }) => identity?.toUpperCase() == market?.toUpperCase()
        );
        findSelectedMarket && selectedMarket.push(findSelectedMarket);
      });
    let defaultMarket = markets?.length > 0 && markets[0]?.marketGroupsList[0];
    market = isInvalidValue(market)
      ? defaultMarket
      : selectedMarket?.length > 0
      ? selectedMarket[0]
      : defaultMarket;

    return market;
  };

  makeUrl(url, path, params) {
    var startUrl = url + path;

    if (params) {
      var c = 0;
      startUrl += "?";
      for (var i in params) {
        var p = params[i];
        startUrl +=
          (c > 0 ? "&" : "") +
          encodeURIComponent(i) +
          "=" +
          encodeURIComponent(p);
        c++;
      }
    }
    return startUrl;
  }

  render() {
    const { Component, authorizedType } = this.props;
    const { isLogin } = this.state;
    const myUserInfo = getLocalStorage("userInfo");
    const userType = myUserInfo ? JSON.parse(myUserInfo).userType : "USER";
    let path = this.props.location.pathname;
    let redirectTo = this.state.redirectTo;

    if (userType === "ADMIN" && path === "/admin") {
      this.authorizedType = "ADMIN";
      redirectTo = "/users";
    }

    if (userType === "ADMIN" && path === "/userdashbord") {
      this.authorizedType = "USER";
      redirectTo = "/summary";
    }

    if (
      (userType === "ADMIN" || userType === "USER") &&
      path === "/selectbrand"
    ) {
      this.authorizedType = "USER";
      redirectTo = "/selectbrand";
    }

    //Redirect to multiple routes in different tabs
    let matchDashboardPath = userDashboardMenus.some((pathValue) =>
      path?.includes(pathValue)
    );
    if (
      userType === "ADMIN" &&
      !matchDashboardPath &&
      path !== "/" &&
      path !== "/admin" &&
      path !== "/selectbrand"
    ) {
      redirectTo = path;
      path = path;
      setLocalStorage("authorizedType", "ADMIN");
      this.authorizedType = "ADMIN";
    } else if (userType === "ADMIN" && matchDashboardPath) {
      redirectTo = path === "/userdashbord" ? "/summary" : path;
      path = path;
      setLocalStorage("authorizedType", "USER");
      this.authorizedType = "USER";
    }

    const existingUserWithoutContracts = verifyExistingUserWithoutContracts();
    if (existingUserWithoutContracts && path === "/selectbrand") {
      redirectTo = "/selectbrand";
    }
    if (!isLogin) {
      return <LoadingLayer />;
    } else if (
      Component &&
      this.authorizedType === authorizedType &&
      path !== "/admin" &&
      path !== "/userdashbord"
    ) {
      return <Component {...this.props} />;
    } else if (
      path !== redirectTo ||
      (userType === "ADMIN" && (path === "/admin" || path === "/userdashbord"))
    ) {
      return <Redirect to={{ pathname: redirectTo }} />;
    }
  }
}

export default AuthCheckWrapper;
