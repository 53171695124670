import React from "react";
import { StyledViewActionsCard } from "./Styled.ViewBulkActions";
import { StyledViewsActions } from "../ViewCard/ViewsActions/Styled.ViewsActions";
import ddIcon from "../../../assets/icons/icon_arrow_down.svg";
import closeIcon from "../../../assets/icons/views_close_icon.svg";
import ViewDeleteIcon from "../../../assets/icons/icon_views_trash.svg";
import ViewEditIcon from "../../../assets/icons/icon_report_edit.svg";
import ButtonWithDropdownOption from "../../ButtonWithDropdownOption";

function ViewBulkActions({
  selectedViewIds,
  handleViewIds,
  handleEditViewClick,
  handleDeleteViewClick,
  handleViewActionModalClick,
}) {
  const handleClickActions = (action, closeDropdown) => {
    action();
    closeDropdown();
    handleViewActionModalClick();
  };
  return (
    <StyledViewActionsCard>
      <div className="view-action-card">
        <div className="content">{selectedViewIds?.length} Views Selected</div>
        <div className="actions">
          <div className="actions-button">
            <StyledViewsActions className="views-header-right-container">
              <ButtonWithDropdownOption
                closeOnContentClick={false}
                trigger={
                  <div className="button-conatiner">
                    Actions
                    <img
                      width={10}
                      height={10}
                      src={ddIcon}
                      alt="views-actions"
                    />
                  </div>
                }
              >
                {(closeDropdown) => (
                  <ul>
                    <li
                      key={`edit_view}`}
                      onClick={() =>
                        handleClickActions(handleEditViewClick, closeDropdown)
                      }
                    >
                      <img height={18} src={ViewEditIcon} alt="views-details" />
                      <span>Edit Date Period</span>
                    </li>
                    <li
                      id="view-delete-action"
                      key={`delete_view}`}
                      onClick={() =>
                        handleClickActions(handleDeleteViewClick, closeDropdown)
                      }
                    >
                      <div className="view-delete-action-button">
                        <img
                          height={18}
                          src={ViewDeleteIcon}
                          alt="views-details"
                        />
                        <span>Delete Views</span>
                      </div>
                    </li>
                  </ul>
                )}
              </ButtonWithDropdownOption>
            </StyledViewsActions>
          </div>
          <div onClick={()=>{handleViewActionModalClick();handleViewIds();}} style={{cursor:'pointer'}}>
            <img src={closeIcon} alt="" height={25} />
          </div>
        </div>
      </div>
    </StyledViewActionsCard>
  );
}

export default ViewBulkActions;
