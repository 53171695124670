import React, { useEffect, useState } from "react";
import DateInput from "react-date-picker";
import "../widgets/OptionFilters/DateSelecter.scss";
import "react-date-picker/dist/DatePicker.css";
import { DateTime } from "luxon";
import ddIcon from "../../assets/icons/icon_arrow_down.svg";

const DateRangePicker = ({
  startDate,
  endDate,
  selectedPeriodType,
  onDateRangeClick,
  periodTypeKey,
}) => {
  const [selectedStartDate, setSelectedStartDate] = useState(
    startDate ? new Date(startDate) : null
  );
  const [selectedEndDate, setSelectedEndDate] = useState(
    endDate ? new Date(endDate) : null
  );
  useEffect(() => {
    setSelectedStartDate(startDate ? new Date(startDate) : null);
    setSelectedEndDate(endDate ? new Date(endDate) : null);
  }, [startDate, endDate]);

  const handleDateChange = (e, key) => {
    let currentDate = DateTime.fromJSDate(new Date(e)).toFormat("MM/dd/yyyy");
    if (key === "selectedStartDate") {
      setSelectedStartDate(e);
    } else {
      setSelectedEndDate(e);
    }

    onDateRangeClick(
      key === "selectedStartDate"
        ? currentDate
        : selectedStartDate === null
        ? selectedStartDate
        : DateTime.fromJSDate(new Date(selectedStartDate)).toFormat(
            "MM/dd/yyyy"
          ),
      key === "selectedEndDate"
        ? currentDate
        : selectedEndDate === null
        ? selectedEndDate
        : DateTime.fromJSDate(new Date(selectedEndDate)).toFormat("MM/dd/yyyy"),
      periodTypeKey
    );
  };
  return (
    <div className="customInputContainer">
      <div className="customInputDate">
        <div className="inputBox dateInput customDateInputContainer">
          {selectedPeriodType === 1 ? (
            <DateInput
              className="dateInput_picker"
              format="M/d/yyyy"
              calendarIcon={<img src={ddIcon} className="arrowIcon" alt="" />}
              clearIcon={null}
              value={selectedStartDate}
              minDate={""}
              maxDate={
                selectedEndDate === null
                  ? DateTime.now().minus({ days: 1 }).toJSDate()
                  : new Date(selectedEndDate)
              }
              onChange={(e) => handleDateChange(e, "selectedStartDate")}
            />
          ) : (
            <span>
              {DateTime.fromJSDate(selectedStartDate).toFormat("MM/dd/yyyy")}
            </span>
          )}
        </div>
        <div className="inputBox dateInput toContainer"> to </div>
        <div className="inputBox dateInput customDateInputContainer">
          {selectedPeriodType === 1 ? (
            <DateInput
              className="dateInput_picker"
              format="M/d/yyyy"
              calendarIcon={<img src={ddIcon} className="arrowIcon" alt="" />}
              clearIcon={null}
              value={selectedEndDate}
              minDate={selectedStartDate}
              maxDate={DateTime.now().minus({ days: 1 }).toJSDate()}
              onChange={(e) => handleDateChange(e, "selectedEndDate")}
            />
          ) : (
            <span>
              {DateTime.fromJSDate(selectedEndDate).toFormat("MM/dd/yyyy")}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default DateRangePicker;
