import React, { Component } from 'react';
import cx from 'classnames';
import './GroupedDropDownSelect.scss';
import ddIcon from '../../../assets/icons/icon_arrow_down.svg';

export default class GroupedDropDownSelect extends Component {
    constructor(props) {
        super(props);

        this.outsideClickListener = this.outsideClickListener.bind(this);
    }

    onHeaderClick = (e) => {
        if (!this.props.open) {
            document.addEventListener('click', this.outsideClickListener)
            this.props.onOpenSelect(e)

        }else {
            document.removeEventListener('click', this.outsideClickListener)
            this.props.onOpenSelect(e)

        }
    }

    outsideClickListener(e) {
        if (this.props.open) {
            this.props.onOpenSelect(e);
        }
        document.removeEventListener('click', this.outsideClickListener);
    }

    handleSelect = (e, cid) => {
        e.preventDefault();
        this.props.onSelectFunction(cid);
    }

    render() {
        const {
            customClass,
            data,
            disabled,
            open,
            openTop,
            placeholder,
            selected,
            skey,
            wider = true,
        } = this.props;
        let selectedData = selected !== ''
            ? data && data.flatMap(opt => opt.options).find(o => o?.id === selected)
            : null;
        return (
            <div className={cx('inputBox', customClass, {'open': open, 'disabled': disabled})} style = {!customClass?{marginRight:'5px'}:{}} onClick = {(e) => this.onHeaderClick(e)}>
                { selectedData ? <span className='inputLabelText'>{selectedData.name}</span> : <label className="placeholder">{placeholder}</label> }
                {!disabled && <span><img src={ddIcon} alt="" /></span>}
                {!disabled &&
                <div className={
                    cx(
                        "selectListContainer",
                        {
                            openTop: openTop,
                            wider: wider,
                        }
                    )}>
                    {data && data.map( (c, i, {length}) => {
                        return (
                            <div className={"optionGroup"}
                                key={skey + i}
                            >
                                <div
                                    className={"optionGroupLabel"}
                                    onClick = {(e) => e.stopPropagation()}
                                >{c.label}</div>
                                {c.options && c.options.map( o => {
                                    return (
                                        <div
                                            key={skey + o.id}
                                            className={
                                                cx(
                                                    "selectItem",
                                                    {
                                                        "selected": o.id === selected,
                                                        "maxContent": wider,
                                                    }
                                                )
                                            }
                                            onClick = {(o.id !== selected) ? (e) => this.handleSelect(e, o.id) : null}
                                        >
                                            {o.name}
                                        </div>
                                    )
                                })}
                                {(length - 1 !== i) && <hr className={"divider"} />}
                            </div>
                        )
                    })}
                </div>
                }
            </div>
        )
    }
}
