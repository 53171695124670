import ApiData from '../../utils/Api';

export function logOut() {
  ApiData.getUserLogout()
    .then((result) => {
      localStorage.clear();
      sessionStorage.clear();

      window.location = result + "&" + encodeURIComponent("returnTo") + "=" + encodeURIComponent(process.env.REACT_APP_AUTH0_COMPLETE_URL);
    })
    .catch((err) => {
      console.log(err)
      window.localStorage.removeItem('userInfo');
      window.localStorage.removeItem('myBrands');
      window.localStorage.removeItem('token');
      window.localStorage.removeItem('authorizedType');
      window.localStorage.removeItem('filters');
      window.localStorage.removeItem('messages');
      window.localStorage.removeItem('sessionInitialized')
      window.location = "/"
    })

}