import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './AddBrandsGeoModal.scss';
import AddBrandsComponent from './AddBrand/AddBrandComponent';
import AddGeoComponent from './AddGeo/AddGeoComponent';

export default class AddBrandsGeoModal extends Component {
    
    static propTypes = {
        title: PropTypes.string.isRequired,
    }

    constructor(props) {
        super(props);
        this.state = {
          newBrands: [],
          newGeos: []
        }
        this.setBrands = this.setBrands.bind(this);
        this.setGeos = this.setGeos.bind(this);
    }

    setBrands =(payload)=>{
       this.setState({newBrands: payload})
    }

    setGeos =(payload)=>{
        this.setState({newGeos: payload})
    }

    render() {
        const { title, closeOpenModal, subjectInfo, currentBrands, currentGeos, currentBrandList, currentGeoList} = this.props;

        return (
            <div className="modalOverlay infoModal">
                <div className="modalContent infoModalContent">
                    <div className="title">
                        <h2 className={'infoModalTitle'}>{title}</h2>
                        {subjectInfo? <p className={'extraInfo'}>{subjectInfo.subtitle}</p>: null}
                    </div>
                    <div className="divider" />
                    <div className="flex-content">
                        { title === "Add Brands"? <AddBrandsComponent setBrands={this.setBrands} currentBrands={currentBrands} selectedBrandList={currentBrandList} /> : <AddGeoComponent setGeos={this.setGeos} currentGeos={currentGeos} selectedGeoList={currentGeoList}/>}
                        <div className="bottom-buttons">
                            <div className="divider" />
                            <div className={'buttonGroup'}>
                                <div className="whiteButton infoModalButton" onClick={()=>closeOpenModal()}>
                                    Cancel
                                </div>
                                <div className="blueButton infoModalButton" onClick={()=>{
                                    title === "Add Brands"? this.props.setBrandListHandler(this.state.newBrands): this.props.setGeoListHandler(this.state.newGeos)
                                    closeOpenModal()
                                    }}>
                                    {title}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
