import React, { Component } from "react";
import "./Views.scss";
import ReportsListComponent from "./ReportsListComponent";
import ViewsReorderableList from "./ViewsReorderableList";
import EquityScrollWrapperHeightService, {
  selectors,
} from "../../utils/equityScrollWrapperHeightService";

class ViewsHomeComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { selectedTab } = this.props;
    return (
      <div style={{ overflowX: "hidden" }}>
        <div
          className="scrollWrapper"
          style={{ height: "calc(100vh - 230px)"}}
        >
          {selectedTab === 0 && <ReportsListComponent data={this.props} />}
          {selectedTab === 1 && <ViewsReorderableList />}
        </div>
      </div>
    );
  }
}
export default ViewsHomeComponent;

