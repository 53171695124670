import React from "react";
import {
  StyledHeaderCardPrimary,
  StyledHeaderCardSecondary,
  StyledHeaderCardPrimaryLarge,
  StyledHeaderCardPrimaryInvertedWithIconOutline,
  StyledHeaderCardPrimaryInvertedWithoutIconOutline,
} from "./Styled.HeaderCard";
import ViewCardTooltip from "../ViewCardTooltip";

function HeaderCardContent({
  title,
  subtitle,
  icon,
  children,
  isChecked,
  enableTitleTooltip,
  enableSubtitleTooltip,
}) {
  return (
    <>
      <div
        className={`header-card-container ${
          children ? "header-card-container-with-action-button" : ""
        } `}
      >
        {icon && (
          <div className="header-card-icon">
            {!isChecked && (
              <img
                src={icon}
                alt="header_card_icon "
                className="header-card-icon-image screentype-logo"
              />
            )}
            <input
              type="checkbox"
              className="MuiCheckbox-root header-card-checkbox"
              inputProps={{ "aria-label": "Checkbox demo" }}
              checked={isChecked}
            />
          </div>
        )}
        <div
          className={`header-card-title-subtitle ${
            icon ? "header-card-title-width-with-icon" : ""
          }`}
        >
          {enableTitleTooltip ? (
            <ViewCardTooltip contentList={[title]} offset={[0, 5]} delay={500}>
              <div className="header-card-title header-card-title-with-tooltip">
                {title}
              </div>
            </ViewCardTooltip>
          ) : (
            <div className="header-card-title">{title}</div>
          )}
          {enableSubtitleTooltip ? (
            <ViewCardTooltip
              contentList={[subtitle]}
              offset={[0, 5]}
              delay={500}
            >
              <div className="header-card-subtitle header-card-subtitle-with-tooltip">
                {subtitle}
              </div>
            </ViewCardTooltip>
          ) : (
            <div className="header-card-subtitle">{subtitle}</div>
          )}
        </div>
      </div>

      {children && <div>{children}</div>}
    </>
  );
}

function HeaderCard({
  title,
  subtitle,
  icon,
  variant,
  children,
  isChecked,
  enableTitleTooltip = false,
  enableSubtitleTooltip = false,
}) {
  if (variant === "primary") {
    return (
      <StyledHeaderCardPrimary>
        <HeaderCardContent
          title={title}
          subtitle={subtitle}
          icon={icon}
          enableTitleTooltip={enableTitleTooltip}
          enableSubtitleTooltip={enableSubtitleTooltip}
        >
          {children}
        </HeaderCardContent>
      </StyledHeaderCardPrimary>
    );
  }
  if (variant === "primaryInvertedWithIconOutline") {
    return (
      <StyledHeaderCardPrimaryInvertedWithIconOutline>
        <HeaderCardContent
          title={title}
          subtitle={subtitle}
          icon={icon}
          enableTitleTooltip={enableTitleTooltip}
          enableSubtitleTooltip={enableSubtitleTooltip}
        >
          {children}
        </HeaderCardContent>
      </StyledHeaderCardPrimaryInvertedWithIconOutline>
    );
  }
  if (variant === "primaryInvertedWithoutIconOutline") {
    return (
      <StyledHeaderCardPrimaryInvertedWithoutIconOutline>
        <HeaderCardContent
          title={title}
          subtitle={subtitle}
          icon={icon}
          enableTitleTooltip={enableTitleTooltip}
          enableSubtitleTooltip={enableSubtitleTooltip}
        >
          {children}
        </HeaderCardContent>
      </StyledHeaderCardPrimaryInvertedWithoutIconOutline>
    );
  }
  if (variant === "primaryLarge") {
    return (
      <StyledHeaderCardPrimaryLarge>
        <HeaderCardContent
          title={title}
          subtitle={subtitle}
          icon={icon}
          enableTitleTooltip={enableTitleTooltip}
          enableSubtitleTooltip={enableSubtitleTooltip}
        >
          {children}
        </HeaderCardContent>
      </StyledHeaderCardPrimaryLarge>
    );
  }
  return (
    <StyledHeaderCardSecondary>
      <HeaderCardContent
        title={title}
        subtitle={subtitle}
        icon={icon}
        isChecked={isChecked}
        enableTitleTooltip={enableTitleTooltip}
        enableSubtitleTooltip={enableSubtitleTooltip}
      >
        {children}
      </HeaderCardContent>
    </StyledHeaderCardSecondary>
  );
}

export default HeaderCard;
