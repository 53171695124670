const prefix = "@@TWO_BY_TWO_CHART";

export const DISABLE_Z_AXIS = `${prefix}__DISABLE_Z_AXIS`;
export const ENABLE_Z_AXIS = `${prefix}__ENABLE_Z_AXIS`;

export const SET_SEGMENTS = `${prefix}__SET_SEGMENTS`;
export const SET_CUSTOM_SEGMENTS = `${prefix}__SET_CUSTOM_SEGMENTS`;
export const DISABLE_SEGMENT = `${prefix}__DISABLE_SEGMENT`;
export const DISABLE_SEGMENTS = `${prefix}__DISABLE_SEGMENTS`;
export const DISABLE_CUSTOM_SEGMENT = `${prefix}__DISABLE_CUSTOM_SEGMENT`;
export const DISABLE_CUSTOM_SEGMENTS = `${prefix}__DISABLE_CUSTOM_SEGMENTS`;
export const ENABLE_SEGMENT = `${prefix}__ENABLE_SEGMENT`;
export const ENABLE_CUSTOM_SEGMENT = `${prefix}__ENABLE_CUSTOM_SEGMENT`;

export const SET_BRANDS = `${prefix}__SET_BRANDS`;
export const DISABLE_BRAND = `${prefix}__DISABLE_BRAND`;
export const ENABLE_BRAND = `${prefix}__ENABLE_BRAND`;

export const SET_CHART_DATA = `${prefix}__SET_CHART_DATA`;

export const CLEAR_ALL_DATA = `${prefix}__CLEAR_ALL_DATA`;

//actions for views
export const SET_REPORTS = `${prefix}__SET_REPORTS`;
export const SET_VIEWS = `${prefix}__SET_VIEWS`;

