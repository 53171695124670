import styled from "styled-components";

const StyledViewsReorderableList = styled.div`
  margin: 24px 10px 10px 36px;
  .views-reports-count-trigger {
    color: #344054;
    font-weight: 500;
    cursor: pointer;
    font-size: 13px;
    img {
      margin-left: 4px;
    }
  }
  .views-header-filter-container {
    display: flex;
    align-items: baseline;
    gap: 18px;
    justify-content: flex-end;
    width: fit-content;
    position: absolute;
    top: 65px;
    right: 30px;
  }
  .views-search-container {
    width: 250px;
    height: 40px;
    gap: 12px;
    display: flex;
    border-radius: 3px;
    border: 1px solid #e4e4e4;
    align-items: center;
    padding-left: 10px;
    background: #fff;
  }
  .views-report-list-container {
    li {
      padding: 6px 8px 6px 8px;
      gap: 8px;
      label {
        cursor: pointer;
      }
      input {
        height: 14px;
        width: 14px;
        clip-path: unset;
      }
    }
  }
`;

export default StyledViewsReorderableList;
