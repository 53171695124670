import axios from "axios";

export const initiateSession = async () => {
  try {
    // Fetch and transform the data from localStorage
    const storedBrands = JSON.parse(localStorage.getItem("globalBrands")) || [];
    const auth_token = localStorage.getItem("token");
    const transformedBrands = storedBrands.reduce((acc, brand) => {
      brand.marketGroups.forEach((marketGroup) => {
        marketGroup.marketGroupsList.forEach((market) => {
          const existingMarket = acc.find(
            (m) => m.marketId === market.marketId
          );

          const brandInfo = {
            id: brand.id,
            name: brand.name,
          };

          if (existingMarket) {
            existingMarket.brands.push(brandInfo);
          } else {
            acc.push({
              marketId: market.marketId,
              code: market.identity,
              marketName: market.marketName,
              brands: [brandInfo],
            });
          }
        });
      });
      return acc;
    }, []);

    // Define user data including transformedBrands
    const userInfo = {
      userData: transformedBrands,
      authToken: auth_token,
    };

    // Make the API call to initialize the session
    const initiationResponse = await axios.post(
      process.env.REACT_APP_ASSISTANT_URL + "/initiatesession/",
      userInfo,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    localStorage.setItem('sessionInitialized', 'true');
    localStorage.removeItem('messages');

    console.log(
      "Session Initiated Successfully",
      initiationResponse.data.assistant_response
    );
  } catch (error) {
    console.log("Session Initialization failed...", error);
  }
};

